import React from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import moment from 'moment/moment';
import { getArticles } from '../../store/actions';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { formatMonth } from '../../data/format-month';
import Loading from '../../component/Loading';

const BlogSidebar = (props) => {

  const { t } = useTranslation()
  const [articles, setArticles] = useState([])
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit: 7,
      offset: 0
    }
    setLoading(true)
    getArticles(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setArticles(res?.results)
      setLoading(false)
    })


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <div className="sidebar-widget-wrapper">
      <div className="sidebar__widget mb-30">
        <div className="sidebar__widget-head mb-35">
          <h4 className="sidebar__widget-title">{t("other_articles")}</h4>
        </div>
        {loading ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", margin: "10% auto" }}>
            <Loading />
          </div>
          :
          <div className="sidebar__widget-content">
            <div className="rc__post-wrapper">
              {
                articles.length ? articles.map((item) => (
                  item.id === Number(props.news_id) ? '' :
                    <div key={item.id} className="rc__post my-3 d-flex align-items-center">
                      <div className="rc__thumb mr-20">
                        <Link to={`/article-details/${item.id}`}>
                          {item.thumbnail && <img src={`${item.thumbnail}`} style={{ width: 'auto', aspectRatio: '1:1' }} alt="img not found" />}
                        </Link>
                      </div>
                      <div className="rc__content">
                        <div className="rc__meta">
                          <span className='capitalize'>{formatDate(item.created_at)}</span>
                        </div>
                        <h6 className="rc__title">
                          <Link to={`/article-details/${item.id}`}>
                            {item.title}
                          </Link>
                        </h6>
                      </div>
                    </div>
                )) : ''
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default BlogSidebar;