import React from 'react';
import { Outlet, NavLink } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import MainMenu from './MainMenu';
import TopBar from '../topbar/Topbar';
import OffCanvasMenu from './OffcanvasMenu';
import useHeaderSticky from '../../hooks/useHeaderSticky';
import './style.scss';
import MiniSliderNews from './MiniNews';
import { useTranslation } from 'react-i18next';

const Header1 = (props) => {
  const [isSticky] = useHeaderSticky();
  const { t } = useTranslation()

  return (
    <>
      <header
        className={`site-header header-default header-sticky ${isSticky ? 'header-sticky' : ''
          } ${props.className || ''}`}
      >
        <TopBar
          className=""
          containerSize="container"
        />
        <div className={`header-wrapper logoBar ${isSticky ? 'min-navbar' : ''}`}>
          <Container >
            <div className="site-year-logo logo-sm">
              <div className='logo-text text-end'>{t("year_logo")}</div>
              <NavLink className="logo-link" to="/">
                <img alt='' src='/images/year_logo.webp' />
              </NavLink>
            </div>
            <div className="header-inner d-flex flex-row justify-content-between align-items-center">

              <div className="site-logo">
                <NavLink className="logo-link" to="/">
                  <img alt='' src='/images/logo.png' />
                  <div className='logo-text'><span className='text-lighter'>{t("iicimet")}</span> <br className='d-xs-none' /> {t("iicimet_short")}</div>
                </NavLink>
              </div>

              <div className="mobile-menu-btn d-lg-none">
                <OffCanvasMenu position="end" />
              </div>
              <div className="site-year-logo logo-lg">
                <NavLink className="logo-link" to="/">
                  <img alt='' src='/images/year_logo.webp' />
                </NavLink>
                <div className='logo-text w-50 text-lighter'>{t("year_logo")}</div>

              </div>
            </div>
          </Container>
        </div>
        <div className="header-wrapper categories-bar">
          <Container className=''>
            <div className="header-inner ">
              <div className="main-menu d-none d-lg-block">
                <MainMenu />
              </div>
            </div>

          </Container>
        </div>
        <div className={`mini-slider-news`}>
          <MiniSliderNews />
        </div>
      </header>
      <Outlet />
    </>
  );
};

export default Header1;
