import React from 'react';
import { useParams, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { getSingleArticle } from '../../store/actions';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import BlogSidebar from './sidebar_article';
import moment from 'moment/moment';
import './blog.scss'
import { formatMonth } from '../../data/format-month';
import { Helmet } from 'react-helmet';
import Loading from '../../component/Loading';

function ArticleSingle() {
  let { id } = useParams();
  const { t } = useTranslation()
  const [data, setData] = useState({})
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')
  const [loading, setLoading] = useState(true)

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const lang = language === 'tm' ? 'tk' : language
    setLoading(true)
    getSingleArticle(dispatch, id, lang, (err, res) => {
      if (err) console.log(err);
      setData(res?.data)
      setLoading(false)
      console.log(res?.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, id])

  return (
    <>
      <Helmet>
        <title>{`${t("articles")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          colSize="col-md-10"
          title={t("articles")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' },
          ]}
        />
        {loading ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", margin: "5% auto" }}>
            <Loading />
          </div>
          :
          <div className="content-wrapper page-template">
            <Container>
              <Row className="justify-content-center">
                <Col xl={8} lg={12}>
                  <article className="about-post blog-post article-post-list">

                    <div className="post-image">
                      <img style={{height: "500px", objectFit: "contain"}} className="w-100" src={Object.keys(data).length ? `${data.thumbnail}` : ''} alt="" />
                    </div>
                    <div className='entry-content'>
                      <div className="entry-meta">
                        <ul>
                          <li key="meta-date" className="entry-meta-date">
                            <div>{Object.keys(data).length ? formatDate(data.created_at) : ''}</div>
                          </li>
                        </ul>
                      </div>
                      <h4 className="entry-title mb-4">{Object.keys(data).length ? data.title : ''}</h4>
                    </div>
                    <div className="entry-content">
                      <div className="entry-description">
                        <div dangerouslySetInnerHTML={{
                          __html: Object.keys(data).length ? data.content : ''
                        }}
                        >
                        </div>
                      </div>
                    </div>
                    {
                      data.source_link
                        ?
                        <div className='entry-button' style={{ width: 250, marginTop: 40, marginRight: '15px', float: 'right' }}>
                          <Link target='_blank' to={data.source_link} className='entry-button-link font-rubik'>
                            <span>{t('source')}</span>
                          </Link>
                        </div>
                        :
                        null
                    }
                  </article>
                </Col>
                <Col xl={4} lg={8} md={8}>
                  <BlogSidebar news_id={id} />
                </Col>
              </Row>
            </Container>
          </div>
        }
      </div>
    </>
  );
}

export default ArticleSingle;
