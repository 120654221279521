import { Link } from 'react-router-dom';
import './footer.scss';
import { useTranslation } from 'react-i18next';

function FooterCopyright() {
  const currentYear = new Date().getFullYear();
  const {t} = useTranslation()

  return (
    <>
      <div className="footer-copyright">
        <div className="container">
          <div className="d-flex justify-content-between align-items-center">
            <div className="text-left">
              <div className="copyright-text">
                © {currentYear} {t("iicimet")} {t("iicimet_short")}. {t("rights_reserved")}.
              </div>
            </div>
            <div className="d-flex align-items-center gap-4">
              <Link href=''>
                <img alt='' src='/images/footer/instagram.svg'/>
              </Link>
              <Link href=''>
                <img alt='' src='/images/footer/imo.svg'/>
              </Link>
              <Link href=''>
                <img alt='' src='/images/footer/tiktok.svg'/>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FooterCopyright;
