import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Offcanvas from 'react-bootstrap/Offcanvas';
import MainMenu from './MainMenu';
import { useTranslation } from 'react-i18next';

function OffCanvasMenu(props) {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { t, i18n } = useTranslation()

  return (
    <>
      <Button onClick={handleShow} className="mobile-menu-trigger d-lg-none">
        <span></span>
        <span></span>
        <span></span>
      </Button>
      <Offcanvas
        className="offcanvas-menu"
        show={show}
        onHide={handleClose}
        responsive="lg"
        placement={props.position}
      >
        <div className="offcanvas-mobile-menu">
          <Offcanvas.Header closeButton>
            <div className="site-logo d-flex gap-2 justify-content-center align-items-center">
              <NavLink className="logo-link" to="/">
                <img alt='' src='/images/logo.png' />
              </NavLink>
              <div className='text-sm font-bold'>{t("iicimet_short")}</div>
            </div>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="mobile-menu">
              <MainMenu />
            </div>

            <div className="d-flex justify-content-center gap-3 align-items-center mt-2">
              <div>
                <img alt='' onClick={() => {
                  i18n.changeLanguage('tk');
                }} src='/images/tk.svg' />
              </div>
              <div>
                <img alt='' onClick={() => {
                  i18n.changeLanguage('ru');
                }} src='/images/ru.svg' />
              </div>
              <div>
                <img alt='' onClick={() => {
                  i18n.changeLanguage('en');
                }} src='/images/en.svg' />
              </div>
            </div>
          </Offcanvas.Body>
        </div>
      </Offcanvas>
    </>
  );
}

export default OffCanvasMenu;
