import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import BlogSidebar from './sidebar_competitions';
import { useDispatch } from 'react-redux';
import { formatMonth } from '../../data/format-month';
import moment from 'moment/moment';
import { getSingleCompetition } from '../../store/actions';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

function CompetitionSingle() {
  const { t } = useTranslation()
  let { id } = useParams();
  const dispatch = useDispatch()
  const language = localStorage.getItem('i18nextLng')
  const [data, setData] = useState({})

  const formatDate = (date) => {
    const month = date.split("T")[0].split("-")[1]
    return `${formatMonth(month, language)} ${moment(date).format(`DD, YYYY`)}`
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    const lang = language === 'tm' ? 'tk' : language
    getSingleCompetition(dispatch, id, lang, (err, res) => {
      if (err) setData({});
      setData(res?.data)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, id])

  return (
    <>
      <Helmet>
        <title>{`${t("competitions")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("competitions")}
          PagaHeaderBg={'/images/bg.svg'}
          description={t("")}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-start">
              <Col xl={8} lg={12} md={12}>
                <div className="edu-webinar">

                  <div className="edu-webinar-text">
                    <h3 className="edu-webinar-text-title">
                      <Link to={`/competition-details/${Object.keys(data).length ? data.id : ''}`}>
                        {Object.keys(data).length ? data.title : ''}
                      </Link>
                    </h3>
                    <ul>
                      <li className='capitalize'>
                        <i className="far fa-calendar-alt"></i>
                        {Object.keys(data).length ? formatDate(data.start_date) : ''}
                      </li>
                      <li>
                        <i className="far fa-clock"></i> {Object.keys(data).length ? moment(data.start_date).format("HH:mm") : ''}
                      </li>
                      <li><div className={`capitalize ${Object.keys(data).length && data.status === 'active' ? 'event-status-active' : 'event-status-notactive'}`}>{Object.keys(data).length && data.status === 'active' ? t("active") : t("inactive")}</div></li>
                    </ul>

                    <div className='edu-webinar-desc' dangerouslySetInnerHTML={{
                      __html: Object.keys(data).length ? data.description : ''
                    }}></div>

                  </div>
                </div>
              </Col>
              <Col xl={4} lg={8} md={8}>
                <BlogSidebar news_id={1} />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default CompetitionSingle;
