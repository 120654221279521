import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getAds } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import Pagination from '../../component/pagination';
import { CaseStudyStyle2 } from '../../component/case-study';
import { Helmet } from 'react-helmet';
import { base } from '../../store/api';

function Advertisement() {
  const dispatch = useDispatch()
  const [ads, setAds] = useState([])
  const {t} = useTranslation()
  const language = localStorage.getItem('i18nextLng')

  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit : 20,
      offset : (currentPage - 1) * 20
    }
    getAds(dispatch, params, lang,  (err, res) => {
        if(err) console.log(err);
        setAds(res?.results)
        setCount(res?.count)
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[language, currentPage])
  
  return (
    <>
      <Helmet>
        <title>{`${t("advertisement")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("advertisement")}
          PagaHeaderBg={'/images/bg.svg'}
          description={t("")}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col sm={12}>
                <div className="blog-wrapper grid-wrapper grid-lg-3 grid-md-2 grid-sm-1">
                  {ads.length ? ads.map((val, ind) => {
                    return (
                      <CaseStudyStyle2
                        key={ind}
                        id={val.id}
                        image={`${base}${val.image}`}
                        title={val.title}
                        category={val.category}
                        description={val.description}
                      />
                    );
                  }) : ''}
                </div>
              </Col>
            </Row>

            <Pagination currentPage={currentPage} count={count} handlePageChange={(e) =>handlePageChange(e)}/>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Advertisement;
