import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

function AboutUs() {
  const {t} = useTranslation()

  return (
    <>
      <Helmet>
        <title>{`${t("about")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("about")}
          PagaHeaderBg={'/images/bg.svg'}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper page-template">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12}>
                <article className="about-post blog-post">
                  <div className="post-image">
                    <img className="w-100" src='/images/pseudo.png' alt="" />
                  </div>
                  <div className='entry-content '>
                    <h4 className=" entry-title mb-4 mt-4">{t("about_ministry")}</h4>
                  </div>
                  <div className="entry-content">
                    <div className="entry-description">
                      {
                        t("about_ministry_description").split("\n").map((e, i) => (
                          <p key={i}>{e}</p>
                        ))
                      }
                    </div>
                  </div>
                </article>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
