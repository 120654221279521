import React, { useState } from 'react';
import Lightbox from 'react-18-image-lightbox';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function ImageGallery(props) {
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const openLightbox = (index) => {
    setIsOpen(true);
    setPhotoIndex(index);
  };

  const closeLightbox = () => {
    setIsOpen(false);
  };

  return (
    <>
      {props.data.map((img, index) => {
        return (
          <div key={index} className='gallery-item gallery-style-2'>
            <div className=" gallery-image relative">
              <img
                className="img-fluid"
                key={index}
                src={`${img}`}
                alt={img.title}
                onClick={() => openLightbox(index)}
              />

            </div>
          </div>

        )
      })}


      {isOpen && (
        <Lightbox
          imagePadding={50}
          mainSrc={`${props.data[photoIndex]}`}
          imageTitle={props.data[photoIndex].title}
          nextSrc={props.data[(photoIndex + 1) % props.data.length]}
          prevSrc={
            props.data[(photoIndex + props.data.length - 1) % props.data.length]
          }
          onCloseRequest={closeLightbox}
          onMovePrevRequest={() =>
            setPhotoIndex(
              (photoIndex + props.data.length - 1) % props.data.length
            )
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % props.data.length)
          }
        />
      )}
    </>
  );
}

export default ImageGallery;
