
import CategoryIcon from '../../component/category-icon';
import { useEffect, useState } from 'react';
import { getServices } from '../../store/actions';
import { useDispatch } from 'react-redux';
import { base } from '../../store/api';

function CategoryIconSection({ isHome }) {
  const language = localStorage.getItem('i18nextLng')
  const [services, setServices] = useState([])
  const dispatch = useDispatch()
  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    getServices(dispatch, {}, lang, (err, res) => {
      if (err) console.log(err);
      setServices(
        isHome
          ? res?.results.slice(0, 12)
          : res?.results
      )
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  return (
    <>
      {
        services.length ? services.map((s, ind) => {
          return (
            <CategoryIcon
              icon={`${base}${s.icon}`}
              title={s.title}
              link={`/service-details/${s.id}`}
            />
          )
        }) : ''
      }
    </>
  );
}

export default CategoryIconSection;
