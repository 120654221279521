import axios from "axios";

export const base = "https://iicmet.gov.tm";

export const axiosInstance = axios.create({
  baseURL: "https://iicmet.gov.tm",
  headers: {
    "Content-Type": "application/json",
    accept: "application/json",
    Authorization: null,
  },
});

export const api = {
  get: async ({ url, params }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    return await axiosInstance.get(url, config);
  },
  post: async ({ url, params }) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        accept: "application/json",
      },
    };
    return await axiosInstance.post(url, params, config);
  },
};
