const initialState = {
    news : [],
    articles : [],
    gallery : [],
    single_news : {},
    single_article : {},
    education : [],
    single_education : {},
    video : [],
    sliders : [],
    ads : [],
    lessons : [],
    faq : [],
    services : [],
    competitions: []
  };
  
  const reducer = (state = initialState, action) => {
    switch (action.type) {
      case "GET_NEWS":
        return {
          ...state,
          news : action.payload,
        };
      case "GET_COMPETITIONS":
        return {
          ...state,
          competitions : action.payload,
        };
      case "GET_SERVICES":
        return {
          ...state,
          services : action.payload,
        };
      case "GET_FAQ":
        return {
          ...state,
          faq : action.payload,
        };
      case "GET_LESSONS":
        return {
          ...state,
          lessons : action.payload,
        };
      case "GET_ADS":
        return {
          ...state,
          ads : action.payload,
        };
      case "GET_SLIDERS":
        return {
          ...state,
          sliders : action.payload,
        };
      case "GET_ARTICLES":
        return {
          ...state,
          articles : action.payload,
        };
      case "GET_EDUCATION":
        return {
          ...state,
          education : action.payload,
        };
      case "GET_GALLERY":
        return {
          ...state,
          gallery : action.payload,
        };
      case "GET_VIDEO":
        return {
          ...state,
          video : action.payload,
        };
      case "GET_SINGLE_NEWS":
        return {
          ...state,
          single_news : action.payload,
        };
      case "GET_SINGLE_EDUCATION":
        return {
          ...state,
          single_education : action.payload,
        };
      case "GET_SINGLE_ARTICLE":
        return {
          ...state,
          single_article : action.payload,
        };
      default:
        return state;
    }
  };
  
  export default reducer;
  