import Accordion from 'react-bootstrap/Accordion';
import './accordion.scss';

function AccordionPGS(props) {
  return (
    <>
      <div className="accordion-main">
        <Accordion
          className="accordion-pgs"
          defaultActiveKey={props.defaultActive || ''}
        >
          {props?.accordionData &&
            props.accordionData.map((item) => (
              <Accordion.Item eventKey={`${item.id}`} key={item.id}>
                <Accordion.Header>{item.question}</Accordion.Header>
                <Accordion.Body>
                  <p className='accordion-course-desc pb-1' dangerouslySetInnerHTML={{
                    __html: item.answer
                  }}>
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            ))}

          {props?.accordionItems &&
            props.accordionItems.map((item) => (
              <Accordion.Item eventKey={`${item.id}`} key={item.id}>
                <Accordion.Header>{item.title}</Accordion.Header>
                <Accordion.Body>{item.content}</Accordion.Body>
              </Accordion.Item>
            ))}
        </Accordion>
      </div>
    </>
  );
}

export default AccordionPGS;
