import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import { useTranslation } from 'react-i18next';
import CategoryIconSection from '../home/category-icon';
import { Helmet } from 'react-helmet';

function Services() {
  const { t } = useTranslation()

  return (
    <>
      <Helmet>
        <title>{`${t("services")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("services")}
          PagaHeaderBg={'/images/bg.svg'}
          description={t("")}
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        <div className="content-wrapper">
          <section className="page-template">
            <Container>
              <Row>
                <Col sm={12}>
                  <div className="category-wrapper">
                    <div className="grid-wrapper grid-lg-6  grid-c-4 grid-md-3 grid-sm-2 grid-gap-0">
                      <CategoryIconSection />
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
}

export default Services;
