import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import PageHeader from '../../layouts/page-header/PageHeader';
import BlogItem from '../../component/blog';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { getArticles } from '../../store/actions';
import { useTranslation } from 'react-i18next';
import Pagination from '../../component/pagination';
import { Helmet } from 'react-helmet';
import Loading from '../../component/Loading';

function Article() {
  const dispatch = useDispatch()
  const [articles, setArticles] = useState([])
  const { t } = useTranslation()
  const language = localStorage.getItem('i18nextLng')
  const [count, setCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true)
  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [])

  useEffect(() => {
    const lang = language === 'tm' ? 'tk' : language
    let params = {
      limit: 20,
      offset: (currentPage - 1) * 20
    }
    setLoading(true)
    getArticles(dispatch, params, lang, (err, res) => {
      if (err) console.log(err);
      setArticles(res?.results)
      setCount(res?.count)
      setLoading(false)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language, currentPage])

  return (
    <>
      <Helmet>
        <title>{`${t("articles")} - ${t("iicimet")} ${t("iicimet_short")}`}</title>
        <meta name="description" content="Lorem ipsum dolor sit amet" />
      </Helmet>
      <div className="site-content">
        <PageHeader
          title={t("articles")}
          PagaHeaderBg={'/images/bg.svg'}
          description=''
          breadCrumbItems={[
            { label: t("home"), path: '/' }
          ]}
        />
        {loading ?
          <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", width: "100%", margin: "5% auto" }}>
            <Loading />
          </div>
          :
          <div className="content-wrapper page-template">
            <Container>
              <Row className="justify-content-center">
                <Col sm={12}>
                  <div className="blog-wrapper grid-wrapper grid-lg-3 grid-md-2 grid-sm-1">
                    {articles.length ? articles.map((val, ind) => {
                      return (
                        <BlogItem
                          key={ind}
                          id={val.id}
                          imgSrc={val.image} date={val.created_at}
                          title={val.title}
                        />
                      );
                    }) : ''}
                  </div>
                </Col>
              </Row>

              <Pagination currentPage={currentPage} count={count} handlePageChange={(e) => handlePageChange(e)} />
            </Container>
          </div>
        }
      </div>
    </>
  );
}

export default Article;
