import Marquee from "react-fast-marquee";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getNews } from "../../store/actions";
import { useDispatch } from "react-redux";

const MiniSliderNews = () => {
    const [news, setNews] = useState([])
    const dispatch = useDispatch()
    const language = localStorage.getItem('i18nextLng')

    useEffect(() => {
        const lang = language === 'tm' ? 'tk' : language
        let params = {
            limit: 5,
            offset: 0
        }
        getNews(dispatch, params, lang, (err, res) => {
            if (err) console.log(err);
            setNews(res.results)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [language])

    return (
        <section className="flex w-full py-1 overflow-x-auto">
            <Marquee pauseOnHover gradient={false} speed={25}>
                {news.length > 0 &&
                    news.map((news, key) => {
                        return (
                            <Link
                                key={key}
                                to={`/news-details/${news.id}`}
                                className="marquee font-rubik"
                            >
                                <img src='/images/lot_angle.svg' alt="angle" height={12} width={12} className="mx-3" />
                                <span>{news.title}</span>
                            </Link>
                        )
                    })
                }
            </Marquee>
        </section>
    );
};

export default MiniSliderNews;
